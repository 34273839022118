<template>

    <Header :menu_items="menu_items" :page="page" :editable="editable" :website="website"/>

    <section class="text-gray-600 body-font section-text">
        <div class="bg-white  dark:bg-black dark:text-white">
            <div>
                <main class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                    <div class="border-b border-gray-200 pt-6 pb-3">

                        <nav class="flex" aria-label="Breadcrumb">
                            <ol class="inline-flex items-center space-x-1 md:space-x-3 ml-3">
                                <li class="inline-flex items-center">
                                    <jet-nav-link href="/"
                                                  class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                                        <svg class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                                        </svg>
                                    </jet-nav-link>
                                </li>
                                <li>
                                    <div class="flex items-center">
                                        <svg class="w-auto h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd"
                                                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                                  clip-rule="evenodd"></path>
                                        </svg>
                                        <jet-nav-link :href="'/'+page.url"
                                                      class="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white ">
                                            {{ page.name }}
                                        </jet-nav-link>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                        <div class="clear-both"></div>



                        <h2 class="text-2xl font-bold text-gray-900 pt-6 dark:text-white">{{website.text_404_product_title ?? 'Helaas! 404 Error'}}</h2>
                        <b>{{website.text_404_product_text ?? 'Helaas de pagina die u zochr bestaat niet meer. Maar we hebben goede alternatieven:'}}</b>

                        <div class="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4 border-t ">
                            <!-- Filters DESKTOP -->
                            <form class="hidden lg:block">
                                <h3>Product categorieën</h3>

                                <ul role="list"
                                    class="space-y-4 border-b border-gray-200 pb-6 text-sm font-medium text-gray-900 mt-2">
                                    <template v-for="(category,key,index) in website.categories" :key="category.name">
                                        <li>
                                            <jet-nav-link :href="('/'+category.url)"
                                                          :class="'!no-underline block py-1 dark:text-white'"
                                            >{{
                                                    category.name
                                                }}
                                            </jet-nav-link>
                                        </li>
                                    </template>
                                </ul>
                            </form>

                        <div class="lg:col-span-3  lg:h-full">
                            <div class="
                            ms:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-12 sm:gap-y-12
                            lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-12 lg:gap-y-12">


                                <div class="lg:col-span-3  lg:h-full mb-10">
                                    <ProductIndex :products="website.search.products" :website="website"></ProductIndex>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </section>

    <Footer  :menu_items="menu_items" :page="page"  :website="website">
        <template #content_website_footer_left>
            <slot name="content_website_footer_left">
            </slot>
        </template>
        <template #content_website_footer_center>
            <slot name="content_website_footer_center">
            </slot>
        </template>
        <template #content_website_footer_right>
            <slot name="content_website_footer_right">
            </slot>
        </template>
    </Footer>
</template>


<script>

import Header from '@/Pages/Admin/Page/Templates/Partials/Header.vue';
import Footer from '@/Pages/Admin/Page/Templates/Partials/Footer.vue';
import ProductIndex from '@/Pages/Admin/Page/Templates/Partials/ProductIndex.vue';
import JetNavLink from "@/Jetstream/NavLink.vue";

export default {

    components: {
        Header,
        Footer,
        JetNavLink,
        ProductIndex
    },
    props: {
        menu_items: Object,
        page: Object,
        editable: Boolean,
        website: Object,
    },

    data() {
        return {}
    },
    methods: {
        itemsContains(n) {
            return this.page.page_options.indexOf(n) > -1
        }
    }
}
</script>

